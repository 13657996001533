.schedule-confirmed{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &>.user-logo{
      height: 48px;
      width: 48px;
    }
    &>.schedule-confirmed-username{
      margin: 24px 0 8px 0;
      font-family: Lato, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 900;
      line-height: 24px;
      letter-spacing: 0.1px;
      text-align: center;
    }
    &>.header-title{
      font-family: Lato, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
      letter-spacing: 0;
      text-align: center;
      color: var(--theme-page-text-color-secondary);
    }
  }
  &-body{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--input-border-color);
    width: 100%;
    &>.body-title{
      font-family: Lato, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0;
      text-align: center;
      margin-bottom: 22px;
    }
    &>ul{
      list-style: none;
      &>li{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: Lato, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0;
        text-align: left;
        margin-bottom: 20px;
        &>span{
          display: flex;
          justify-content: center;
          align-items: center;
            width: 20px;
          height: 20px;
          border-radius: 50%;
          font-family: Lato, sans-serif;
          font-size: 11px;
          font-style: normal;
          font-weight: 700;
          letter-spacing: 0;
          text-align: center;
          border: 1px solid var(--button-background-color);
          color: var(--button-background-color);
          margin-right: 8px;
        }
      }
      &>li:last-child{
        margin-bottom: 0;
      }
    }
  }
  &-footer{
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    &>.footer-title{
      font-family: Lato, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0;
      text-align: center;
      margin-bottom: 16px;
    }
    &>a{
      width: inherit;
      &>button{
        width: inherit;
      }
    }
  }
}

@media (max-width: 576px) {
  .schedule-confirmed{
    padding: 0 24px;
  }
}