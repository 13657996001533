@import "variables";

body {
  margin: 0;
  font-family: $font-family-main;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #595959;
}

.fw-regular {
  font-weight: 500;
}

.fw-medium {
  font-weight: 500;
}

.fw-semi-bold {
  font-weight: 600;
}

.fw-bold {
  font-weight: 700;
}


.line-height-normal {
  line-height: 100%
}
