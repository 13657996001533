.completeSchedule{
  &-body{
    &> .completeSchedule-form{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap:24px;
      & .phone-input-container {
        width: 100%;
        & .react-tel-input .form-control.invalid-number{
          border-color: inherit;
        }
        & .phone-input-input{
          width: 100%;
        }
      }
      &>.input-with-label{
        position: relative;
        &>.add-guests{
          font-family: Lato, sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px;
          letter-spacing: 0;
          text-align: left;
          cursor: pointer;
          position: absolute;
          right: 0;
          top:0;
          color: var(--button-background-color);
        }
      }
      &>.styled-button{
        display: flex;
        justify-content: center;
        align-items: center;
        &>div{
          margin-right: 12px;
        }
      }
    }
  }
  &-header{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    &>.user-logo{
      margin-top: 8px;
      width: 48px;
      height: 48px;
    }
    &-username{
      margin-top: 16px;
      font-family: Lato, sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 900;
      line-height: 24px;
      letter-spacing: 0.1px;
      text-align: center;
    }
    &>.completeSchedule-backButton{
      color: var(--button-background-color);
      font-family: Open Sans, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      & svg{
        width: 8px;
        stroke: var(--button-background-color);
      }
      &>.back-title{
        margin-left: 12px;
      }
    }
  }
  &-title{
    font-family: Lato, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 24px;
  }
}

@media (max-width: 576px){
  .completeSchedule{
    &-body{
      padding: 0 24px;
    }}
}