.congratulations-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 384px;
  font-family: Open Sans, sans-serif;
  text-align: center;
  &>.congratulations-logo{
    width: 88px;
    height: 88px;
    border: 4px solid var(--copy-link-button-background);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-bottom: 24px;
    & svg{
      width: 40px;
      height: 40px;
      & path{
        fill: var(--copy-link-button-background);
      }
    }
  }
  &>.congratulations-title{
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0;
    margin-bottom: 4px;
  }
  &>.congratulations-subtitle{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0;
    color: var(--button-background-color);
    margin-bottom: 24px;
  }
  &>.congratulations-content{
    text-align: center;
    margin-bottom: 24px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
  }
}

@media (max-width: 576px) {
  .congratulations-container{
    width: auto;
  }
}
