.light {
    --theme-page-background: #FFFFFF;
    --theme-page-background-secondary: #FFFFFF;
    --theme-page-text-color: #595959;
    --theme-page-text-color-secondary: #595959;
    --theme-header-color: #231F20;
    --button-background-color: #096DD9;
    --button-background-hover-color: #1890FF;
    --button-text-color: #FFFFFF;
    --button-border-color: #096DD9;
    --button-revers-text-color:#096DD9;
    --button-revers-background-color:#FFFFFF;
    --border-color: #D9D9D9;
    --input-background-color: #FFFFFF;
    --phone-validation-error: #d79f9f;
    --sider-background-color: #FAFAFA;
    --input-border-conference-phone:#096DD933;
    --dropdown-background: #FFFFFF;
    --sider-active-item: #F5F5F5;
    --input-border-color: #D9D9D9;
    --switch-background-color: #D9D9D9;
    --copy-link-button-background: #08979C;
    --meeting-settings-header-color: #000000;
    --modal-background-color: rgba(9,109,217,0.8);
    --dropdown-hover-background-color: #F5F5F5;
    --alarm-button-color: #FF4D4F;
    --picker-background: #F5F5F5;
    --picker-color: #595959;
    --calendar-logo-background: #F5F5F5;
    --calendar-disabled-days-color: #BFBFBF;
    --calendar-month-disabled-days-color: #BFBFBF;
    --schedule-background: linear-gradient(108.94deg, rgba(68, 68, 68, 0) 0%, rgba(0, 0, 0, 0.19) 100%);
    --message-success-background-color: #08979C;
    --message-error-background-color: #A8071A;
    --billing-failed-color: #CF1322;
    --calendary-arrow-color: #8C8C8C;






    --input-background-color-almost-transparent: #FFFFFF05;
    --input-error-text-color: #8C8C8C;
    --input-error-icon-color: #CF1322;
    --input-ok-icon-color: #08979C;
    --input-url-background-color: rgba(230, 247, 255, 1);
    --input-url-border-color: #40A9FF;
    --input-url-text-color: #262626;
    --days-border-color: #D9D9D9;
    --days-item-background: #FFFFFF;
    --days-item-background-active: #096DD9;
    --days-item-active-icon: #FFFFFF;
    --days-item-active-text: #FFFFFF;
    --days-item-inactive-icon: #8C8C8C;
    --days-item-inactive-text: #8C8C8C;
    --available-time-slider-slide-background-color: #FFFFFF;
    --available-time-slider-active-track-background-color: #096DD9;
    --timezone-selector-text-color: #2B2B2B;

    --switch-background-color-active: #096DD9;
    --switch-label-text-color: #262626;
}

.dark {
    --theme-page-background: #231F20;
    --theme-page-background-secondary: #2B2B2B;
    --theme-page-text-color: #FFFFFF;
    --theme-page-text-color-secondary: #BFBFBF;
    --theme-header-color: #FFFFFF;
    --button-background-color: #FFB800;
    --button-background-hover-color: #FFCE4F;
    --button-text-color: #2B2B2B;
    --button-border-color: #FFB800;
    --button-revers-text-color:#FFFFFF;
    --button-revers-background-color:#231F20;
    --border-color: #2B2B2B;
    --input-background-color: #2B2B2B;
    --phone-validation-error: #d79f9f;
    --sider-background-color: #000000;
    --input-border-conference-phone:#FFB80033;
    --dropdown-background: #424242;
    --input-border-color: #595959;
    --switch-background-color: #8C8C8C;
    --copy-link-button-background: #08B1B7;
    --meeting-settings-header-color: #FFFFFF;
    --modal-background-color: rgba(35,31,32,0.8);
    --dropdown-hover-background-color: rgba(43,43,43,0.5);
    --alarm-button-color: #FF4D4F;
    --picker-background: #424242;
    --picker-color: #8C8C8C;
    --calendar-logo-background: #595959;
    --calendar-disabled-days-color: #8C8C8C;
    --calendar-month-disabled-days-color: #595959;
    --schedule-background: #231F20;
    --message-success-background-color: #08B1B7;
    --message-error-background-color: #FF4D4F;
    --billing-failed-color: #F5222D;
    --calendary-arrow-color: #8C8C8C;






    --sider-active-item: #262626;
    --input-background-color-almost-transparent: #2B2B2B30;

    --input-error-text-color: #595959;
    --input-error-icon-color: #CF1322;
    --input-ok-icon-color: #08979C;
    --input-url-background-color: rgba(255, 184, 0, 0.2);
    --input-url-border-color: #FFB800;
    --input-url-text-color: #FFFFFF;
    --days-border-color: #595959;
    --days-item-background: #2B2B2B;
    --days-item-background-active: #FFB800;
    --days-item-active-icon: #3F3E40;
    --days-item-active-text: #231F20;
    --days-item-inactive-icon: #BFBFBF;
    --days-item-inactive-text: #BFBFBF;
    --available-time-slider-slide-background-color: #2B2B2B;
    --available-time-slider-active-track-background-color: #FFB800;
    --timezone-selector-text-color: #FAFAFA;

    --switch-background-color-active: #FFB800;
    --switch-label-text-color: #F5F5F5;
}
