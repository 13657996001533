@import "utils";
@import "typography";
@import "spacing";
@import "fixAnt";
@import 'hamburger';
@font-face {
  font-family: Lato;
  src: local('Lato'), url(./fonts/Lato-Regular.ttf) format('opentype');
}
@font-face {
  font-family: Open Sans;
  src: local('Open Sans'), url(./fonts/OpenSans-Regular.ttf) format('opentype');
}
@font-face {
  font-family: Recoleta Bold;
  src: local('Recoleta Bold'), url(./fonts/Recoleta-Bold.ttf) format('opentype');
}
@font-face {
  font-family: Proxima Nova;
  src: local('Proxima Nova'), url(./fonts/Proxima-Nova.ttf) format('opentype');
}

// TODO Transfer classes below to separate files

.link-text-color {
  color: #4484f0
}

.gray-text-color {
  color: #333333
}

.text-note-color {
  color: #828282;
}

.text-header {
  font-size: 24px;
  font-weight: bold;
}

.text-header-sub {
  font-size: 20px;
  font-weight: bold;
}

.text-header-menu {
  font-size: 15px;
}

.text-note {
  font-size: 12px;
}

.text-header-menu-sub {
  font-size: 15px;
  font-weight: bold;
}

.textarea-resize-off {
  resize: none;
}

.textarea-resize-off textarea {
  resize: none;
}

.text-color-red {
  color: red;
}

.margin-top-bottom-align-center{
  margin: 15px 0;
  text-align: center;
}

.header-unauthorized-error-text{
  color: red;
  width: 100%;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}

//----new design----
body::-webkit-scrollbar {
  display: none;
}
.ant-layout{
  background-color: var(--theme-page-background);
  overflow: hidden;
  height: 100vh;
  ul{
    padding: 0;
    margin: 0;
  }
  a:hover{
    //color: var(--theme-header-color);
  }
}
.route-wrapper {
  position: relative;
}

.route-wrapper > div {
  position: absolute;
  width: 100%;
  height: inherit;
}
//tooltip style
.tooltip-container{
  & .ant-tooltip-content{
    &>.ant-tooltip-inner{
      background-color: var(--dropdown-background);
      color: var(--theme-header-color);
      border-radius: 6px;
    }
  }
  & .ant-tooltip-arrow-content{
    display: none;
  }
}

@media (max-width: 576px) {
  #root>.ant-layout {
    //Mobile viewport height calc
   // height: calc(var(--vh, 1vh) * 100);
    margin-bottom: calc(100vh - (var(--vh, 1vh) * 100));
  }
}
