$width: 234px;

.phone-input-container {
  width: $width;
  .phone-input-input {
    background: var(--input-background-color);
    border: 1px solid var(--input-border-color);
    border-left: none;
    box-sizing: border-box;
    border-radius: 4px;
    color: var(--theme-page-text-color);
    width: 234px;
    padding-left: 48px;
    height: 40px;
    line-height: 22px;
  }

  .phone-input-button {
    background: var(--input-background-color);
    border: 1px solid var(--input-border-color);
    border-right: none;
    box-sizing: border-box;
    border-radius: 4px 0px 0px 4px;
    color: var(--theme-page-text-color);

    &.open {
      background-color: var(--input-background-color);
      border-radius: 4px;
    }

    .open.selected-flag {
      background-color: var(--input-background-color);
      border-radius: 4px;
    }

    .selected-flag {
      padding-left: 13px;
      width: 40px;
      border-radius: 4px;
    }

    .flag {
      transition: transform .25s ease-in-out;
    }

    .selected-flag:hover,
    .selected-flag:focus {
      background-color: var(--input-background-color);

      .flag {
        transform: scale(1.2);
      }
    }

    .arrow {
      display: none;
    }
  }

  .phone-input-dropdown {
    background-color: var(--input-background-color);
    backdrop-filter: blur(1px);
    border: 1px solid var(--input-border-color);
    border-radius: 4px;
    width: $width;

    .country.highlight {
      background-color: var(--input-background-color);
      border-top: 1px solid var(--input-border-color);
      border-bottom: 1px solid var(--input-border-color);
    }

    .country {
      position: relative;
      z-index: 3;

      &:hover {
        background-color: var(--input-background-color);
      }
    }

    &::-webkit-scrollbar {
      width: 9px;
    }

    &::-webkit-scrollbar-track {
      background: var(--input-background-color);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--input-border-color);
      border: 1px solid var(--input-background-color);
    }
  }
}
.react-tel-input {
  .form-control.invalid-number,.form-control.invalid-number:active,.form-control.invalid-number:focus{
    color: var(--theme-page-text-color);
    background-color: var(--input-background-color);
    border-color: var(--phone-validation-error);
  }
  .flag-dropdown.invalid-number{
    border-color: var(--phone-validation-error);
  }
}