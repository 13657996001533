.billingTrial{
  width: 100%;
  &-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 34px;
    &>.header-title{
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0;
      margin-bottom: 16px;
    }
    &>.header-subtitle{
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: center;
      margin-bottom: 24px;
    }
    &>.styled-button{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;
      & svg{
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }
      & path{
        fill: var(--button-text-color);
        stroke: transparent;
      }
    }
    &>.header-price{
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0;
      text-align: center;

    }
  }
  &-body{
    &>.body-header{
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0;
      text-align: center;
      margin-bottom: 43px;
    }
    &>.body-content{
      display: flex;
      justify-content: center;
      &>.col{
        &>.col-title{
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 0;
          text-align: center;
        }
        &>ul{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          list-style: none;
          gap:12px;
          padding-top: 12px;
          &>li{
            text-align: start;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            &>.badge{
              margin-left: 12px;
            }
            & svg{
              margin-right: 12px;
              & path{
                stroke: transparent;
                fill: var(--button-background-color);
              }

            }
          }

        }
      }
      &>.col.platinum{
        &>ul{
          &>li:last-child{
            font-weight: 600;
          }
        }
      }
      &>.col:first-child{
        border-right: 1px solid var(--input-border-color);
        padding-right: 64px;
        & path{
          fill: var(--theme-header-color);
        }
      }
      &>.col:last-child{
        padding-left: 64px;
      }
    }
  }
}

@media (max-width: 1200px){
  .billingTrial{
    &>.billingTrial-body{
      &>.body-content{
        &>.col:first-child{
          padding-right: 50px;
        }
        &>.col:last-child{
          padding-left: 50px;
        }
      }
    }
  }
}

@media (max-width: 768px){
  .billingTrial{
    padding-top: 10px;
    &>.billingTrial-body{
      &>.body-content{
        flex-direction: column-reverse;
        margin-bottom: 60px;
        &>.col{
          &>ul{
            padding-left: 30%;
          }
        }
        &>.col:first-child{
          padding-right: 0;
          border-right: none;
          padding-top: 32px ;
        }
        &>.col:last-child{
          padding-left: 0;
          padding-bottom: 32px ;
          border-bottom: 1px solid var(--input-border-color);
        }
      }
    }
  }
}

@media (max-width: 576px){
  .billingTrial{
    &>.billingTrial-body{
      &>.body-content{
        &>.col{
          &>ul{
            padding-left: 3%;
          }
        }
      }
    }
  }
}