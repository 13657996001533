@import "variables";
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  font-family: $font-family-main;
  color: var(--phone-validation-error);
  font-size: 14px;
}
.ant-message{
  z-index: 1500;
}
.ant-form-item-has-error .ant-input{
  background-color: var(--input-background-color);
  border-color: var(--phone-validation-error);
}
.ant-form-item-has-error .ant-input:hover{
  background-color: var(--input-background-color);
}
.ant-form-item-explain.ant-form-item-explain-error{
  color: var(--phone-validation-error);
  font-family: Open Sans, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0;
}
.ant-message-notice{
  &.message-success-container{
    &>.ant-message-notice-content{
      background: var(--message-success-background-color);
    }
  }
  &.message-error-container{
    &>.ant-message-notice-content{
      background: var(--message-error-background-color);
    }
  }
  &>.ant-message-notice-content{
    width: 360px;
    height: 54px;
    font-family: Open Sans, sans-serif;
    color: #FFFFFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
    border-radius: 4px;

    &>.ant-message-custom-content{
      display: flex;
      align-items: center;
      height: 100%;
    }
    &>.ant-message-custom-content{
      &>span:first-child{
      margin-right: 12px;
    }
    }
  }
}
.ant-input[disabled]{
  color: var(--input-url-text-color);
  background-color: var(--input-background-color);
  &:hover{
    border-color: var(--input-border-color);
  }
}
@media (max-width: 576px){
  .ant-message-notice{
    &>.ant-message-notice-content{
      width: 320px;
    }
  }
}
