.google-sing-up-button {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    border-radius: 999px;
    max-width: 267px;
    width: 100%;
    color: var(--button-text-color);
    box-shadow: 0px 0px 0px 0px var(--button-background-color);
    transition: box-shadow .35s ease-in-out, transform .25s ease;

    &.j-button.ant-btn-primary {
        background-color: var(--button-background-color);
        border: 1px solid var(--button-background-color);
    }

    &:hover {
        color: var(--button-text-color);
        box-shadow: 0px 0px 5px 0px var(--button-background-color);
    }

    &.ant-btn {

        &:focus {
            color: var(--button-text-color);
            transform: scale(0.95);
        }

    }
}

.google-sing-up-button--logo {
    margin-right: 12px;

    svg path {
        color: var(--button-text-color);
    }
}