.book-guests-field{
  display: flex;
  flex-wrap: wrap;
  align-content: baseline;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 15px 10px 15px;
  outline: none;
  cursor: text;
  -webkit-appearance: none;
  appearance: none;
  gap:5px;
  color: var(--theme-header-color);
  background-color: var(--input-background-color);
  border: 1px solid var(--input-border-color);
  border-radius: 6px;
  min-height: 40px;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
  &:hover{
      box-shadow: 0 0 0 2px var(--input-url-background-color);
  }

  &>input{
    background-color: var(--input-background-color);
    color: var(--theme-header-color);
    padding: 0;
    flex-grow: 1;
    width: auto;
    min-width: 30px;
    appearance: none;
    resize: none;
    display: block;
    box-sizing: border-box;
    font: inherit;
    border: none;
    &:focus{
      outline: none;
    }
  }

  &>.guest-item{
    font-size: 12px;
    font-weight: 600;
    padding: 0 8px;
    background-color: var(--button-background-color);
    color: var(--button-text-color);
    border-radius: 4px;
    display: inline-flex;
    &>.close-button{
      background-color: var(--button-background-color);
      border: none;
      cursor: pointer;
      font: inherit;
      margin-left: 10px;
      font-weight: bold;
      padding: 0;
      line-height: 1;
      opacity: 0.4;
      &>svg{
        width: 10px;
        height: 10px;
      }
      & path{
        fill:var(--button-text-color);
      }

    }
  }
}