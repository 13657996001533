.monthView-container{
  display: flex;
  overflow: hidden;
  height: 290px;
}
@media (max-width: 576px){
  .monthView-container {
    height: auto;
    flex-direction: column;
  }
}