.calendarInvitation-container{
  width: 768px;
  display: flex;
  border-radius: 6px;
  &>.calendarInvitation-edit{
    flex-basis: 472px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    background-color: var(--theme-page-background-secondary);
    border-radius: 6px 0 0 6px;
    &>.edit-header{
      font-family: Open Sans, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      letter-spacing: 0;
      align-self: center;
      margin-bottom: 24px;

    }
    &>form>.input-with-label{
      margin-bottom: 24px;
    }
    & .edit-buttons{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &>.edit-cancel-button{
        cursor: pointer;
        color: var(--theme-header-color);
        font-family: Lato, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0;
        margin-right: 32px;

      }
    }


  }
  &>.calendarInvitation-variables{
    flex-basis: 296px;
    padding: 32px;
    background-color: var(--dropdown-background);
    border-radius: 0 6px 6px 0;
    display: flex;
    flex-direction: column;
    &>.variables-header{
      font-family: Open Sans, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0;
      align-self: center;
      margin-bottom: 24px;
    }
    &>.variables-items{
      &>.variables-item{
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        font-family: Open Sans, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0;

        &>.variable-name{

        }
        &>.variable-copy{
          font-weight: 600;
          cursor: pointer;
          color: var(--button-background-color);
        }
        &>.variable-copy.copied{
          //cursor: not-allowed;
          color: var(--copy-link-button-background);
        }

      }
    }
  }
}